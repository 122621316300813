<template>
  <div>
    <CommonListLabel :label="title" />
    <CommonDivider :dark="true" />

    <ul class="list-title-xxxs">
      <template v-for="(article, index) in articles" :key="index">
        <li>
          <NuxtLink
            :to="{
              name: currentPaper ? 'paper_article' : 'article',
              params: currentPaper
                ? { paper: currentPaper, id: article.UrlKey }
                : { id: article.UrlKey },
            }"
            class="flex relative"
          >
            <CommonImageLoader
              v-if="article.Writers && article.Writers[0]"
              :height="64"
              :width="64"
              :alt="article.Writers[0].Name"
              class="w-16 h-16 rounded-full bg-black bg-opacity-10 mr-2 shrink-0 overflow-hidden"
              :src="
                isWriter(article.Writers[0])
                  ? 'https://' +
                    config.public.site.legacyurl +
                    '/images/writers/' +
                    appendToFilename(article.Writers[0].Image, '-2')
                  : article.Writers[0].MasterId &&
                    article.Writers[0].MasterId !== 0
                  ? 'https://legacy.altinget.dk/images/person/' +
                    article.Writers[0].MasterId +
                    '/' +
                    article.Writers[0].Image
                  : 'https://' +
                    config.public.site.legacyurl +
                    '/images/person/' +
                    article.Writers[0].RecordId +
                    '/' +
                    article.Writers[0].Image
              "
            />
            <div>
              <div
                v-if="article.Writers && article.Writers[0]"
                class="list-label opacity-35 mb-1"
              >
                {{ article.Writers[0].Name }}
              </div>
              <div
                v-else-if="article.CustomWriter"
                class="list-label opacity-35 mb-1"
              >
                {{ article.CustomWriter }}
              </div>
              <div class="list-title-xxxs">
                {{ article.HeadLine || article.Headline }}
              </div>
            </div>
          </NuxtLink>
        </li>
        <CommonDivider />
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { Writer, Person } from '~/typesManual/content_api/article'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

const indexStore = useIndexStore()

const props = withDefaults(
  defineProps<{
    count?: number
    articleType?: number
    paper?: number
    title?: string
  }>(),
  {
    count: 5,
    articleType: 71,
    title: 'AnalysesFrom',
  }
)

const isWriter = (object: Writer | Person): object is Writer => {
  return (object as Writer)?.Type === 'writer'
}

const { data: articles } = await useAsyncData(async () => {
  return await nuxtApp.$api.content.articles({
    paper:
      props.paper ||
      (indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1),
    id: props.articleType,
    type: 'articletype',
    count: props.count,
  })
})
const currentPaper = computed(() => {
  if (true) {
    return
  }

  return indexStore.currentPaperSlug
})
</script>
