<template>
  <div v-show="!empty">
    <CommonListLabel :label="label" />
    <CommonDivider :dark="true" />
    <ol
      class="list-decimal list-outside list-title-xxxs"
      :class="true ? 'text-blue' : 'text-red'"
    >
      <template v-for="(article, index) in articles" :key="index">
        <li class="ml-5 pl-2">
          <transition name="skeleton" mode="out-in">
            <NuxtLink
              v-if="typeof articles === 'object'"
              :to="{
                name: indexStore.currentPaperSlug ? 'paper_article' : 'article',
                params: indexStore.currentPaperSlug
                  ? {
                      paper: indexStore.currentPaperSlug,
                      id: article.UrlKey,
                    }
                  : { id: article.UrlKey },
              }"
              class="block"
            >
              <span class="text-black" v-html="article.Headline" />
            </NuxtLink>
            <ContentLoader
              v-else
              :key="'li' + index"
              :height="30"
              :width="200"
              :primary-color="indexStore.skeleton.primaryColor"
              :secondary-color="indexStore.skeleton.secondaryColor"
            >
              <rect x="0" y="0" rx="3" ry="3" width="200" height="10" />
              <rect x="0" y="16" rx="3" ry="3" width="180" height="10" />
            </ContentLoader>
          </transition>
        </li>
        <CommonDivider />
      </template>
    </ol>
  </div>
</template>

<script setup lang="ts">
import { ContentLoader } from 'vue-content-loader'

const indexStore = useIndexStore()
const route = useRoute()
const nuxtApp = useNuxtApp()

const props = withDefaults(
  defineProps<{ subject?: number; label?: string }>(),
  { subject: 0, label: 'MostRead' }
)

const { data: articles } = await useAsyncData(async () => {
  let tempSubject = props.subject.toString()

  // Overwrite subject if we are on the KV21 or RV21 election pages
  if (
    false &&
    route.params.subject &&
    (route.params.subject === '7000' || route.params.subject === '7001')
  ) {
    tempSubject = route.params.subject
  }

  return await nuxtApp.$api.content.mostRead(
    indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1,
    tempSubject
  )
})

const empty = computed(() => articles.value?.length === 0)
</script>
