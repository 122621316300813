<template>
  <CommonLoading v-if="loading" class="mx-auto" />
  <form
    v-else
    class="ml-auto mr-auto grid gap-4"
    :class="inPaywall ? 'w-full' : 'w-64'"
    @submit.prevent="submit"
    id="form_user_login"
  >
    <FormAlert :type="alertType" :message="alertMessage" />

    <FormAlert v-if="ssoUrl" type="info">
      <span
        class="prose text-sm leading-tight prose-a:text-blue prose-a:underline prose-a:hover:text-denimblue prose-a:hover:no-underline"
        v-html="$t('sso.YourEmailIsOnAnSSOClient', { url: ssoUrl })"
      />
    </FormAlert>
    <FormAlert v-if="!isPasswordSet" type="info">
      <span
        class="prose text-sm leading-tight prose-a:text-blue prose-a:underline prose-a:hover:text-denimblue prose-a:hover:no-underline"
      >
        {{ $t('PasswordHasNotBeenSet') }}
        <span
          class="text-sm font-sans font-medium text-blue underline cursor-pointer hover:no-underline"
          @click="showForgotPassword"
        >
          {{ $t('ClickToGetALinkSent') }}
        </span>
      </span>
    </FormAlert>

    <FormInput
      ref="emailinput"
      :modelValue="email"
      type="email"
      required
      placeholder="Email"
      @blur="checkEmail"
      @update:modelValue="$emit('update:email', $event)"
    />

    <FormPassword
      :modelValue="password"
      placeholder="Password"
      :pattern="false"
      :minlength="false"
      @update:modelValue="$emit('update:password', $event)"
    />

    <input
      data-testid="popup-login-button"
      type="submit"
      class="cursor-pointer py-3 px-4 text-white article-label !font-medium w-full rounded-full"
      :value="$t('Login')"
      :class="
        true
          ? 'bg-black transform translate-y-px'
          : 'bg-blue hover:bg-denimblue'
      "
    />
    <a
      v-if="(false || true) && allowWayf"
      href="/wayf/"
    >
      <img class="mx-auto py-2 px-4" src="/wayf-logo.png" alt="WAYF login" />
    </a>
    <slot />
  </form>
</template>

<script setup lang="ts">
import { SSOProvider } from '~/typesManual/sso'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const dataLayer = useDataLayer()

const userStore = useUserStore()

const emit = defineEmits<{
  (e: 'update:password', value: string): void
  (e: 'update:email', value: string): void
  (e: 'showForgotPassword'): void
}>()

const showForgotPassword = () => {
  emit('showForgotPassword')
}
const isPasswordSet = ref(true)
const loading = ref(false)
const emailinput = ref<HTMLInputElement>()

const props = withDefaults(
  defineProps<{
    email: string
    password: string
    allowWayf?: boolean
    inPaywall?: boolean
  }>(),
  { allowWayf: true }
)

async function submit() {
  loading.value = true
  clearAlert()

  const ssoProviderInfo = await nuxtApp.$api.user.getSsoProviderByEmail(
    props.email
  )
  if (!ssoProviderInfo) {
    const result = await userStore.loginUser(props.email, props.password)
    if (result.status === 'error') {
      emit('update:password', '')
      if (result.code === 401) {
        setAlert('Wronglogin')
      } else if (result.code === 500) {
        setAlert('ServerError')
        const capturedError = new Error('Internal Server Error: ', {
          cause: result.error,
        })
        if (import.meta.client) {
          nuxtApp.$sentry.captureException(capturedError)
        }
      } else {
        setAlert('DefaultError')
        const capturedError = new Error('Unknown Error: ', {
          cause: result.error,
        })
        if (import.meta.client) {
          nuxtApp.$sentry.captureException(capturedError)
        }
      }
      loading.value = false
      return
    }

    dataLayer.basic.trackUserLogin()
  } else {
    ssoProvider.value = ssoProviderInfo
  }

  loading.value = false
}

const ssoProvider = ref<SSOProvider | null>()

const ssoUrl = computed(() => {
  if (import.meta.server || !ssoProvider.value) return ''

  const isIdentity = ssoProvider.value.providerName === 'IdentityEntraId'

  const url = new URL(
    isIdentity
      ? `${window.location.origin}/api/auth/sign-in-sso`
      : ssoProvider.value.providerUrl
  )
  url.searchParams.set(
    'returnurl',
    window.location.origin + window.location.pathname
  )
  return url.toString()
})
async function checkEmail() {
  if (props.email) {
    const { err, data } = await nuxtApp.$api.user.isPasswordSet(props.email)
    if (err) {
      console.error('Error in isPasswordSet:', err.cause)
    } else if (data !== undefined) {
      isPasswordSet.value = data
    }
    // Single Sign-On checks
    if (config.public.site.allowSingleSignOn) {
      ssoProvider.value = await nuxtApp.$api.user.getSsoProviderByEmail(
        props.email
      )
    } else {
      ssoProvider.value = null
    }
  }
}

// Error handling
const { setAlert, clearAlert, alertType, alertMessage } = useAlert()
</script>
